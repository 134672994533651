import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { tabletS, mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import StrengthsCard from './StrengthsCard'

const DevStrengths = props => (
  <Root {...props}>
    <Card
      title={(
        <Fragment>業務システム</Fragment>
      )}
      body={(
        <Fragment>顧客管理、販売管理、在庫管理、プロジェクト管理、勤怠管理など様々な業務系アプリケーションの開発を行います。 現在ではほとんどのシステムがデータベースとWebによってイントラネットとして構築されています。</Fragment>
      )}
    />
    <Card
      title={(
        <Fragment>FAシステム</Fragment>
      )}
      body={(
        <Fragment>FAシステムは、工場における生産工程の自動化を図るシステムです。 PLCなどの産業用制御システムを使い、CAD、CAMなどと連携してプロセスを制御します。</Fragment>
      )}
    />
    <Card
      title={(
        <Fragment>監視制御システム</Fragment>
      )}
      body={(
        <Fragment>ビル管理（電気・空調・照明など）、太陽光発電監視とデータ分析、各種プラントの監視制御システム、設備診断など 様々な監視制御システムの開発やテストを行います。</Fragment>
      )}
    />
    <Card
      title={(
        <Fragment>組み込みソフト</Fragment>
      )}
      body={(
        <Fragment>組み込みソフトとは、家電製品や産業機器に組み込まれた特定の機能を提供するためのコンピュータシステムのソフトウェアやデバイスに組み込むプロセッサのファームウェアです。 アセンブラやC言語によるリアルタイム制御を行います。</Fragment>
      )}
    />
  </Root>
)

export default ThemeConsumer(DevStrengths)

const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Card = styled(StrengthsCard)`
  width: 50%;
  @media ${mq.and(tabletS, mobile)} {
    width: 100%;
  }
`
