import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { tablet, mobile } from '@/utils/media'
import { mq, aspectRatio, aspectRatioChild } from '@/utils/helpers'
import Image from '@/components/common/Image'

const ServiceClientCard = props => (
  <Root theme={props.theme}>
    <Container>
      <Image fluid={props.image.childImageSharp.fluid} css={styles.Image} />
    </Container>
  </Root>
)

export default ThemeConsumer(ServiceClientCard)

const Root = styled.div`
  ${aspectRatio(1, 1)};
  background-color: ${props => props.theme.background.contrast};
`

const Container = styled.div`
  ${aspectRatioChild};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 26px;
  @media ${mq.and(tablet, mobile)} {
    padding: 8px;
  }
`

const styles = {
  Image: css`
    width: 100%;
  `
}
