import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { tablet, mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Typography from '@/components/common/Typography'
import Image from '@/components/common/Image'
import GhostButton from '@/components/common/GhostButton'

const ProductCard = props => (
  <Root theme={props.theme} className={props.className}>
    <Main>
      <Logo fluid={props.image.contrastLogo.childImageSharp.fluid} alt={props.name} />
      <Description variant="body2" type="contrast">{props.description}</Description>
    </Main>
    <Foot>
      <Button component="a" href={props.link} target="_blank" type="contrast">製品ページへ</Button>
    </Foot>
  </Root>
)

export default ThemeConsumer(ProductCard)

const Root = styled.div`
  display: flex;
  flex-direction: column;
  padding: 48px 32px;
  background-color: ${props => props.theme.background.contrast};
  @media ${mq.and(tablet)} {
    padding: 32px 24px;
  }
  @media ${mq.and(mobile)} {
    padding: 32px 16px;
  }
`

const Main = styled.div`
  flex-shrink: 1;
  flex-grow: 1;
`

const Logo = styled(Image)`
  width: 100%;
  max-width: 180px;
  @media ${mq.and(mobile)} {
    margin: 0 auto;
  }
`

const Description = styled(Typography)`
  margin-top: 24px;
`

const Foot = styled.div`
  flex-shrink: 0;
  flex-grow: 0;
  @media ${mq.and(mobile)} {
    text-align: center;
  }
`

const Button = styled(GhostButton)`
  margin-top: 24px;
  width: 180px;
`
