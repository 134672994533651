import React from 'react'
import styled from '@emotion/styled'
import { graphql, StaticQuery } from 'gatsby'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { tablet, mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import ClientCard from './ClientCard'

const ServiceLead = props => (
  <StaticQuery
    query={query}
    render={ data => {
      const clients = data.allClientListYaml.edges.map(el => el.node)
      return (
        <Root>
          <Parent>
            {clients.map((client, index) => (
              <Child key={index}>
                <ClientCard {...client} />
              </Child>
            ))}
          </Parent>
        </Root>
      )
    }}
  />
)

export default ThemeConsumer(ServiceLead)

const Root = styled.div`
`

const Parent = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: -8px -4px 0;
  @media ${mq.and(tablet, mobile)} {
    margin: -8px -4px 0;
  }
`

const Child = styled.li`
  margin-top: 8px;
  padding: 0 4px;
  width: ${100 / 4}%;
  @media ${mq.and(tablet, mobile)} {
    margin-top: 8px;
    padding: 0 4px;
    width: ${100 / 3}%;
  }
`

const query = graphql`
  query ServiceClientListQuery {
    allClientListYaml {
      edges {
        node {
          image {
            childImageSharp {
              fluid(maxWidth: 320) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
