import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import Typography from './Typography'

const Title = props => {
  return (
    <Root {...props} />
  )
}

const Root = styled(Typography)`
  position: relative;
  padding-left: 20px;
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0.15em;
    left: 0;
    height: 1em;
    width: 4px;
    background-color: currentColor;
  }
`

export default ThemeConsumer(Title)
