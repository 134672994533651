import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { mobile, mobileS } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Typography from '@/components/common/Typography'
import Title from '@/components/common/Title'
import Strengths from '@/components/modules/Service/Dev/Strengths'
import LinkButton1 from '@/components/common/LinkButton1'

const DevLead = props => {
  return (
    <Root>
      <Main>
        <Title variant="h2" component="h3">受託開発事業</Title>
        <Body variant="h4" component="p">システム導入計画の策定から構築・運用サポートまで、最先端の技術でお客様をサポートいたします。</Body>
        <Strengths css={styles.Strengths} />
      </Main>
      <Foot>
        <Button component={Link} to="/services/dev/" scale="800">さらに詳しく</Button>
      </Foot>
    </Root>
  )
}

const Root = styled.div``

const Main = styled.div``

const Body = styled(Typography)`
  margin-top: 36px;
  @media ${mq.and(mobile)} {
    margin-top: 24px;
  }
`

const Foot = styled.div`
  @media ${mq.and(mobile)} {
    text-align: center;
  }
`

const Button = styled(LinkButton1)`
  margin-top: 64px;
  width: 278px;
  @media ${mq.and(mobile)} {
    margin-top: 40px;
  }
  @media ${mq.and(mobileS)} {
    width: 100%;
  }
`

const styles = {
  Strengths: css`
    margin-top: 48px;
    @media ${mq.and(mobileS)} {
      margin-top: 32px;
    }
  `
}

export default ThemeConsumer(DevLead)
