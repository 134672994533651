import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { mq, rgba } from '@/utils/helpers'
import { tablet, mobile } from '@/utils/media'
import Typography from '@/components/common/Typography'
import ParticleBackground from '@/components/common/ParticleBackground'

const ServiceClientCard = props => {
  const { title, body, ...others } = props
  return (
    <Root {...others}>
      <Title variant="h4" theme={props.theme}>
        <Background scale={400} />
        <Title_Text>{title}</Title_Text>
      </Title>
      <Body variant="body2">{body}</Body>
    </Root>
  )
}

const Root = styled.div`
  border-top: 1px solid ${props => rgba(props.theme.scale[400], 0.2)};
  border-bottom: 1px solid ${props => rgba(props.theme.scale[400], 0.2)};
`

const Background = styled(ParticleBackground)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const Title = styled(Typography)`
  position: relative;
  padding: 18px 32px;
  border-bottom: 1px solid ${props => rgba(props.theme.scale[400], 0.2)};
  @media ${mq.and(tablet)} {
    padding: 18px 24px;
  }
  @media ${mq.and(mobile)} {
    padding: 18px 16px;
  }
`

const Title_Text = styled.span`
  position: relative;
`

const Body = styled(Typography)`
  padding: 32px;
  @media ${mq.and(tablet)} {
    padding: 32px 24px;
  }
  @media ${mq.and(mobile)} {
    padding: 32px 16px;
  }
`

export default ThemeConsumer(ServiceClientCard)
