import React, { Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import PageBase from '@/components/base/Page'
import SEO from '@/components/head/seo'
import LD from '@/components/head/linking-data'
import LDEntity from '@/utils/linking-data'
import { mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import PageTitle from '@/components/common/PageTitle'
import imgTitle from '@/images/title-services.png'
import MainMessage from '@/components/common/MainMessage'
import ClientList from '@/components/modules/Service/ClientList'
import DevLead from '@/components/modules/Service/Dev/Lead'
import ProductList from '@/components/modules/Product/List'
import Breadcrumb from '@/components/common/Breadcrumb.container'
import ContactLead from '@/components/modules/Contact/Lead'
import RecruitLead from '@/components/modules/Recruit/Lead'

const ServicesIndexPage = props => (
  <StaticQuery
    query={query}
    render={data => {
      const siteMeta = data.site.siteMetadata
      const company = data.company.edges[0].node
      return (
        <Root>
          <SEO location={props.location} title="事業内容" description="株式会社ウィザードの事業内容についての紹介。業務システム、FAシステム、制御監視システム、組み込みソフトなどの各種システムの受託開発とノウハウと技術力を活かした自社プロダクトの開発を行なっています。"/>
          <LD json={{
            '@type': 'Service',
            'name': '事業内容',
            'description': '株式会社ウィザードの事業内容についての紹介。業務システム、FAシステム、制御監視システム、組み込みソフトなどの各種システムの受託開発とノウハウと技術力を活かした自社プロダクトの開発を行なっています。',
            'provider': LDEntity.Corporation(siteMeta, company)
          }}/>
          <Head>
            <PageTitle src={imgTitle}>事業内容</PageTitle>
            <MainMessage
              title={(
                <Fragment>百戦錬磨の経験から生み出された開発力</Fragment>
              )}
              body={(
                <Fragment>2,000件を超える圧倒的な開発実績が信頼の証。<br />ウィザードが開発する優れた安定性・拡張性・保守性・操作性・機能性を持つシステムは、お客さまから高い評価を獲得しています。</Fragment>
              )}
            />
            <ClientList />
          </Head>
          <Main>
            <Row><DevLead /></Row>
            <Row><ProductList /></Row>
            <Row css={styles.Breadcrumb}><Breadcrumb path={props.location.pathname}/></Row>
            <Row css={styles.Contact}><ContactLead /></Row>
            <Row css={styles.Recruit}><RecruitLead /></Row>
          </Main>
        </Root>
      )
    }}
  />
)

const Root = styled.div``

const Head = styled.div``

const Main = styled.div`
  margin-top: 60px;
  @media ${mq.and(mobile)} {
    margin-top: 40px;
  }
`

const Row = styled.div`
  position: relative;
  padding: 64px 0;
  @media ${mq.and(mobile)} {
    padding: 32px 0;
  }
`

const styles = {
  Breadcrumb: css`
    padding: 0;
    @media ${mq.and(mobile)} {
      padding: 40px 0 0;
    }
  `,
  Contact: css`
    padding: 0;
    @media ${mq.and(mobile)} {
      padding: 0;
    }
  `,
  Recruit: css`
    padding: 0;
    @media ${mq.and(mobile)} {
      padding: 0;
    }
  `
}

export default PageBase(ServicesIndexPage)

const query = graphql`
  query ServicesPageQuery {
    site {
      siteMetadata {
        siteUrl
        title
        description
        keywords
        image
      }
    }
    company: allCompanyInfoYaml {
      edges {
        node {
          name
          legalName
          telephone
          email
          address {
            streetAddress
            addressLocality
            addressRegion
            postalCode
            addressCountry
          }
        }
      }
    }
  }
`
