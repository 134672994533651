import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { graphql, StaticQuery } from 'gatsby'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { desktop, laptop, tabletL, tabletS, mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Typography from '@/components/common/Typography'
import Card from './Card'

const ProductList = props => (
  <StaticQuery
    query={query}
    render={ data => {
      const products = data.allProductListYaml.edges.map(el => el.node)
      return (
        <Root theme={props.theme}>
          <Main>
            <Title variant="h2" component="h3">製品開発・運用事業</Title>
            <Body variant="h4" component="p">経験と技術力を活かした自社プロダクトを開発しています。</Body>
          </Main>
          <Main>
            <List>
              <List_Parent>
                {products.map((product, index) => (
                  <List_Child key={index}>
                    <Card css={styles.Card} {...product} />
                  </List_Child>
                ))}
              </List_Parent>
            </List>
          </Main>
        </Root>
      )
    }}
  />
)

export default ThemeConsumer(ProductList)

const Root = styled.div`
`

const Main = styled.div``

const Title = styled(Typography)`
  position: relative;
  padding-left: 20px;
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0.25em;
    left: 0;
    height: 1em;
    width: 4px;
    background-color: currentColor;
  }
`

const Body = styled(Typography)`
  margin-top: 36px;
  @media ${mq.and(mobile)} {
    margin-top: 24px;
  }
`

const List = styled.div`
  margin-top: 24px;
`

const List_Parent = styled.ul`
  @media ${mq.and(desktop, laptop, tabletL)} {
    display: flex;
    flex-wrap: wrap;
    margin: -16px -8px 0;
  }
`

const List_Child = styled.li`
  @media ${mq.and(desktop, laptop, tabletL)} {
    margin-top: 16px;
    width: 50%;
    padding: 0 8px;
  }
  @media ${mq.and(tabletS, mobile)} {
    margin-top: 8px;
  }
`

const styles = {
  Card: css`
    height: 100%;
  `
}

const query = graphql`
  query ProductListQuery {
    allProductListYaml {
      edges {
        node {
          image {
            screenshot {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            mainLogo {
              childImageSharp {
                fluid(maxWidth: 450) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            contrastLogo {
              childImageSharp {
                fluid(maxWidth: 320) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          name
          description
          link
          color {
            start
            stop
            type
          }
        }
      }
    }
  }
`
